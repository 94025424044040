import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const isLoggedIn = localStorage.getItem('loggedin') === 'true';
    return (
        <Route
          {...rest}
          render={(props) =>
            isLoggedIn ? (
              <Component {...props} />
            ) : (
              <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
            )
          }
        />
      );
    };
    

export default ProtectedRoute