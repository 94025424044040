import React, { useState , useEffect } from 'react'
import './Auth.css'
import logo from '../images/logo/logo.jpg'
import yt from '../images/yt.jpg'
import tele from '../images/tele.jpg'
import wt from '../images/wt.jpg'
import call from '../images/call.jpg'
import { registerUser, loginUser } from '../services/AuthService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { getWhatsappNumber , updatePackage} from '../services/PackageService';
import { updatePassword } from '../services/AuthService';
import environment from '../environment.json'

const AuthenticationPage = () => {


    const [islogin , setIsLogin] = useState(true);

    const handleView = () =>{
        setIsLogin(!islogin);
    }
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [semail, setSemail] = useState('');
    const [spassword, setSpassword] = useState('');
    const [sname, setSname] = useState('');
    const [sphone, setSphone] = useState('');
    const history = useHistory();
    const [flag , setFlag] = useState(false);
    const [toast , setToast] = useState({type:  '' , message : ''});
    const [wnumber, setWhatsappNumber] = useState([]);
    const [number, setNumber] = useState([]);
    const [cpassword , setCpassword] = useState();
    const [ccpassword , setCcpassword] = useState();
    const [cemail , setCemail] = useState();

    // Function to handle form submission
    
    useEffect(() => {
        // Declare the async function inside useEffect
        const getWhatsappNmber = async () => {
          try {
            const response = await getWhatsappNumber(`${environment.domain}tests/getWhatsappNumber`);
    
            if (response.status === 200 || response.status === 201) {
              setWhatsappNumber(response.data[0].whatsappnumber); // Assuming response.data is the correct path to your data
              setNumber(response.data[0].phonenumber);
              
            } else {
              
            }
    
            // Navigate on successful fetch
          } catch (error) {
            console.error('Error fetching tests:', error.message);
          }
        };
        // Call the function
        getWhatsappNmber();
      }, []);
      
      const openWhatsApp = () => {
        // Phone number to chat with (replace with your desired phone number)
        const phoneNumber = wnumber;
        // Message to be sent (optional, replace with your desired message)
        const message = ``;
    
        // Construct the WhatsApp URL
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    
        // Navigate to WhatsApp chat link
        window.open(whatsappURL);
      };
    
    
      const handleTelegramClick = () => {
        // Open Telegram desktop app
        window.open('https://t.me/+AWbZcSnT5eg2ODVl');
      };


    const handleLogin = async (e) => {
      // Create JSON object with input values
      const formData = {
        email,
        password
      };

      try {
        const response = await loginUser(`${environment.domain}auth/login/` , formData);
     
        if (response.status === 200 || response.status === 201) {
            setToast({ type: 'alert alert-success', message : response.data.message});
            setIsLogin(true)
            setFlag(true)
            localStorage.setItem("loggedin", true)
            localStorage.setItem("username" , response.data.name)
            localStorage.setItem("user_id" , response.data.user_id)
            localStorage.setItem("notes_status" , response.data.notes_status)
            
            localStorage.setItem("email" , response.data.email)
            const response2 = await updatePackage(`${environment.domain}tests/updatePackage/${response.data.user_id}`);
            if (response2 === true) {
              localStorage.setItem("package_id" ,"0")
            }else{
              localStorage.setItem("package_id" , response.data.package);
            }
          }else{
            setToast({ type: 'alert alert-danger', message : response.data.message})
            setFlag(true)
          }
        history.push('/dashboard')
        // Handle successful submission
      } catch (error) {
        // Handle error
        console.error('Error submitting form data:', error.message);
      }
    };

   

    const openDialer = (phoneNumber) => {
        window.location.href = `tel:${phoneNumber}`;
      };



      const handleChangePassword = async () =>{
          if (cemail !== "") {
            if (cpassword === ccpassword) {
              const data = 
              {
                "email":cemail,
                "password":cpassword
              }
            
              try {
                const response = await updatePassword(`${environment.domain}auth/changePassword/`, data);
                console.log(response)
                if (response.status === 200 || response.status === 201) {
                  setToast({ type: 'alert alert-success', message : response.data.message});
                  setFlag(true)
                }else{
                  setToast({ type: 'alert alert-danger', message : response.data.message})
                  setFlag(true)
                }
                // Handle successful submission
              } catch (error) {
                // Handle error
                console.error('Error submitting form data:', error.message);
              }
          
            }else{
              alert("Both the Passwords must be same")
            }
          }else{
            alert("Email must not be empty")
          }
      }
    const handleSignup = async (e) => {
        e.preventDefault();
        setFlag(false)
        // Create JSON object with input values
        const formData = {
          semail,
          spassword,
          sname, 
          sphone
        };
  
        try {
          const response = await registerUser(`${environment.domain}auth/register/` , formData);
          console.log(response)
          if (response.status === 200 || response.status === 201) {
            setToast({ type: 'alert alert-success', message : response.data.message});
            setIsLogin(true)
            setFlag(true)
          }else{
            setToast({ type: 'alert alert-danger', message : response.data.message})
            setFlag(true)
          }
          // Handle successful submission
        } catch (error) {
          // Handle error
          console.error('Error submitting form data:', error.message);
        }
    
        // Do something with the formData object, like sending it to an API endpoint
        console.log(formData);
      };
    return (
        <>
            { flag ? (<div class={`${toast.type} alert alert-dismissible fade show`} role="alert">
                        {toast.message}
                        <button onClick={()=>setFlag(false)} type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div> ):('')
            } 
            <div className='authboard shadow-lg my-3'>

                <div className='loginLeft'>
                    <div className='logo'>
                        <img className='rounded-circle' src={logo} />
                        <h4 className='text-center my-3'>Janki Steno and Typing Classes</h4>
                        <h6 className='text-center text-white  my-3'>Join our platform to make your score better and practise well</h6>
                    </div>
                </div>

               { islogin ? ( <div className='loginRight'>
                        <h3 className=''>Welcome</h3>
                        <div class=" text-center mb-3">
                            <div class="col-sm-10">
                                <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" class="form-control" id="inputEmail3" placeholder='Email' />
                            </div>
                        </div>
                        <div class=" mb-3">
                            <div class="col-sm-10">
                                <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" class="form-control" id="inputPassword3" placeholder='Password' />
                            </div>
                        </div>

                        <div class=" mb-3">
                            <div class="col-sm-10 d-flex">
                                
                                <div data-bs-toggle="modal" data-bs-target="#exampleModal" class="form-check offset-sm-1 text-primary">
                                    <label style={{cursor:"pointer" , textDecoration : "underline"}} class="form-check-label" for="gridCheck1">
                                        Forgot Password ?
                                    </label>
                                </div>
                            </div>
                        </div>
                        <button type="submit" onClick={handleLogin} className="loginbtn btn btn-primary">Sign in</button>
                    <hr />

                    <p className='text-center'>Join Us</p>
                    <p className='text-center'>Contact Number : {number}</p>
                    <h5 className='cpointer registerText text-success' onClick={handleView}>Click Here to Register</h5>
                    <p className='text-center'>Stay Connected With Us Using</p>
                    <div className='d-flex justify-content-center conn'>
                    { number ? (
                    <img onClick={openWhatsApp} className='rounded-circle border border-success' src={wt} />
                  ):('')    
                }
                <img onClick={handleTelegramClick} className='rounded-circle border border-success' src={tele} />
                { wnumber ? (
                    <img onClick={() => openDialer(number)} className='rounded-circle border border-success' src={call} />
                ):('')
                }
                    </div>
                </div>):(
                <div className='loginRight'>
                        <h3 className=''>Create An Account</h3>
                        <div class=" text-center mb-3">
                            <div class="col-sm-10">
                                <input value={semail} onChange={(e)=>setSemail(e.target.value)} type="email" class="form-control" id="inputEmail3" placeholder='Email' />
                            </div>
                        </div>
                        <div class=" text-center mb-3">
                            <div class="col-sm-10">
                                <input value={sphone} onChange={(e)=>setSphone(e.target.value)}  type="text" class="form-control" id="inputEmail3" placeholder='Mobile' />
                            </div>
                        </div>
                        <div class=" text-center mb-3">
                            <div class="col-sm-10">
                                <input value={sname} onChange={(e)=>setSname(e.target.value)}  type="text" class="form-control" id="inputEmail3" placeholder='Name' />
                            </div>
                        </div>
                        <div class=" mb-3">
                            <div class="col-sm-10">
                                <input value={spassword} onChange={(e)=>setSpassword(e.target.value)}  type="password" class="form-control" id="inputPassword3" placeholder='Password' />
                            </div>
                        </div>
                        
                        <button type="submit" onClick={handleSignup} className="loginbtn btn btn-primary">Register</button>
                    <hr />

                    <h5 className='' onClick={handleView}>Already Have an Account ? <span className='cpointer registerText text-success'> Login Here </span></h5>
                  
                </div>)
                }
            </div>

            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Change Password</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <label for="inputPassword8" class="form-label">Email</label>
<input onChange={(e)=>setCemail(e.target.value)} value={cemail} type="text" id="inputPassword8" class="form-control" aria-describedby="passwordHelpBlock"/>

      <label for="inputPassword5" class="form-label">Password</label>
<input onChange={(e)=>setCpassword(e.target.value)} value={cpassword} type="password" id="inputPassword5" class="form-control" aria-describedby="passwordHelpBlock"/>


<label for="inputPassword5" class="form-label">Confirm Password</label>
<input onChange={(e)=>setCcpassword(e.target.value)} value={ccpassword} type="text" id="inputPassword5" class="form-control" aria-describedby="passwordHelpBlock"/>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button"  data-bs-dismiss="modal" aria-label="Close" onClick={handleChangePassword} class="btn btn-primary">Change Password</button>
      </div>
    </div>
  </div>
</div>



        </>
    )
}

export default AuthenticationPage