import React from 'react'
import './Result.css'
const JsDiff = require('diff');

const Sresult = ({ userInput , originalText , otherData , type}) => {
    let resultStr = "";
    let wrongwordcount = -1;
    let correctWordChar = 0;
    let wrongWordChar =  0;
    let correctWords = 0;

    // Calculate the total character excluding the spaces
    let totalCharactersTyped = userInput.trim().replace(/\s+/g, '').length;
    let convertUserInput = userInput.split(" ");
    let originalTextArray  = originalText.split(" ");

    const showResult = () => {
        const salt = "Алексей";
        userInput += " " + salt;
        let baseArr = originalText.split(" ");
        let newArr = userInput.split(" ");
        let diff = JsDiff.diffWords(baseArr.join(" "), newArr.join(" "));
        let finalResultString = [];
        try{
            for (let i = 0; i < diff.length; i++) {
                // Normal case when no addition and removal
                if ( diff[i]["added"] === undefined && diff[i]["removed"] === undefined ){

                    // Code to get the no error string let and excluding the spaces count.
                    let trimmedValue = diff[i]['value'].trim();
                    let length = trimmedValue === '' ? 0 : trimmedValue.split(' ').length;
                    correctWordChar += trimmedValue.replace(/\s+/g, '').length;
                    correctWords += length;

                    finalResultString.push(
                        <span style={{color: "green"}}>
                            {diff[i]["value"]}
                        </span>
                    );
                }
                // when there is just removale
                else if ( diff[i]['removed'] !== undefined && diff[i]["removed"] === true && diff[i]["value"] !== "\n") {
                    // Case 1: remove and next one is add.
                    
                    if ( i+1 < diff.length && diff[i+1]["added"] !== undefined && diff[i+1]["added"] !==  false && diff[i+1]["value"] != salt){

                        let ith_wordCharCount = diff[i]['value'].trim().replace(/\s+/g, '').length;
                        let _ith_wordCharCount = diff[i+1] == salt ? (diff[i+1]['value'].trim().replace(/\s+/g, '').length):(0);
                        
                        wrongwordcount+=1;

                        wrongWordChar += (ith_wordCharCount + _ith_wordCharCount);
                        finalResultString.push(
                            <span style={{color: "red"}}>
                               {"{"} {diff[i+1]["value"]} {"}"}
                            </span>
                        );
                        finalResultString.push(
                            <span style={{color: "blue"}}>
                               {"{"} {diff[i]["value"]} {"}"}
                            </span>
                        );
                        i++;
                    }
                    // Case 2: just remove
                    else{
                        wrongwordcount+=(diff[i]["value"].split(" ").length - 1)
                        finalResultString.push(
                            <span style={{color: "red"}}>
                                {diff[i]["value"]} 
                            </span>
                        );
                    }
                }
                // Handling the add case
                else if ( diff[i]['added'] !== undefined && diff[i]["added"] === true && diff[i]["value"] !== "\n"){
                    if (diff[i]["value"].trim().toLowerCase() != salt.trim().toLowerCase()){
                        let ith_wordCharCount = diff[i]['value'].trim().replace(/\s+/g, '').length;
                        wrongWordChar += (ith_wordCharCount);
                        wrongwordcount+=1;
                        // Case 1:
                        finalResultString.push(
                            <span style={{color: "red"}}>
                                {"{"} {diff[i]["value"]} {"}"}
                            </span>
                        );
                    }
                    
                }else{
                    continue;
                }
                
            }
        }catch(err){
            finalResultString.push(
                <span style={{color: "red"}}>
                    {"Error in finding the difference!!"}
                </span>
            );
        }
        wrongwordcount++;
        resultStr = (
            <span>
                {finalResultString}
            </span>
        );
    }
    
    showResult();

  return (
    <>
         <div className='stenoStats row'>
      <p className='col-sm-1 mx-3 px-2 py-2 rounded-pill border border-2 border-primary '>Total : {otherData.total_words} Words </p>
      <p className='col-sm-1 mx-3 px-2 py-2 rounded-pill border border-2 border-success '>Typed : {convertUserInput.length} Words </p>
      <p className='col-sm-1 mx-3 px-2 py-2 rounded-pill border border-2 border-danger '>Incorrect : {wrongwordcount == -1 ? 0 : wrongwordcount} Words </p>
      <p className='col-sm-1 mx-3 px-2 py-2 rounded-pill border border-2 border-primary '>Marks : {(((otherData.total_words - wrongwordcount)/otherData.total_words)*100).toFixed(2)} </p>
    </div>
       
    <div class="main my-3 shadow-lg">
        <p className=''>{resultStr && resultStr }</p>
    </div>
    
    
    </>
  )
}

export default Sresult