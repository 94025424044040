import React, { useEffect } from 'react';

const PDFViewer = ({ pdfUrl }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.11.338/pdf.min.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = function () {
      const loadingTask = window.pdfjsLib.getDocument(pdfUrl);
      loadingTask.promise.then(function (pdf) {
        const numPages = pdf.numPages;
        const scale = 1.5;

        for (let i = 1; i <= numPages; i++) {
          pdf.getPage(i).then(function (page) {
            const viewport = page.getViewport({ scale: scale });
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };

            page.render(renderContext);
            document.body.appendChild(canvas);
          });
        }
      });
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [pdfUrl]);

  return <div></div>;
};

export default PDFViewer;