import {React , useEffect, useState} from 'react'
import './packages.css'
import Navbar from '../components/navbar/Navbar'
import { getAllPackages , getWhatsappNumber} from '../services/PackageService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import environment from '../environment.json'


const Packages = () => {

  const [packages , setPackages] = useState([]);
  const history = useHistory();

  const [wnumber, setWhatsappNumber] = useState([]);


  useEffect(() => {
    // Declare the async function inside useEffect
    const getWhatsappNmber = async () => {
      try {
        const response = await getWhatsappNumber(`${environment.domain}tests/getWhatsappNumber`);

        if (response.status === 200 || response.status === 201) {
          setWhatsappNumber(response.data[0].whatsappnumber); // Assuming response.data is the correct path to your data
        } else {
          console.log("Response:", response);
        }

        // Navigate on successful fetch
      } catch (error) {
        console.error('Error fetching tests:', error.message);
      }
    };
    // Call the function
    getWhatsappNmber();
  }, []);
  
  const openWhatsApp = (price) => {
    // Phone number to chat with (replace with your desired phone number)
    const phoneNumber = wnumber;
    // Message to be sent (optional, replace with your desired message)
    const message = `Hello! I want to pay ${price} for the package`;

    // Construct the WhatsApp URL
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    // Navigate to WhatsApp chat link
    window.open(whatsappURL);
  };




  useEffect(() => {
    // Declare the async function inside useEffect
    const getAllPackage = async () => {
      try {
        const response = await getAllPackages(`${environment.domain}tests/getAllPackages`);

        if (response.status === 200 || response.status === 201) {
          setPackages(response.data); // Assuming response.data is the correct path to your data
        } else {
          console.log("Response:", response);
        }

        // Navigate on successful fetch
      } catch (error) {
        console.error('Error fetching tests:', error.message);
      }
    };
    // Call the function
    getAllPackage();
  }, []);

  return (
    <>
    <Navbar/>
    <div className='d-flex mainc'>
      <img className='my-3 py-3 px-3 mx-3' height={500} width={300} src='/images/qr.jpeg'/>
    <div class="cards">
      <div class="row">
      {
        packages && packages.map((item, index) => {
          return (
            <div key={index} class="col-md-3 card shadow-lg">
              <h4>{item.heading}</h4><br/>
              <h6>Rs: {item.price} <span className='duration'>/ {item.duration} days</span></h6>
              <p>{item.details.split(",").map((detail, index) => (
                <><span key={index}>{detail.trim()}</span><br/></>))}
              </p>
              <button class="btn btn-outline-success" onClick={()=>openWhatsApp(item.price)}>Buy Now</button>    
            </div>
          );
        })
      }


      </div>
    </div>
    </div>
    </>
  )
}

export default Packages