const getAllPackages = async (url) => {
    try {
      const response = await fetch(url, {
        method: 'GET'
      });
  
      const responseData = await response.json(); // Parse JSON response
      return {status : response.status , data : responseData};
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };


  const getWhatsappNumber = async (url) => {
    try {
      const response = await fetch(url, {
        method: 'GET'
      });
  
      const responseData = await response.json(); // Parse JSON response
      return {status : response.status , data : responseData};
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };


  const updatePackage = async (url) => {
    try {
      const response = await fetch(url, {
        method: 'GET'
      });
  
      const responseData = await response.json(); // Parse JSON response
      return responseData;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };
  // Export the service functions individually
  export {getAllPackages , getWhatsappNumber , updatePackage };