import React, { useState , useEffect} from 'react'
import Navbar from '../components/navbar/Navbar'
import { getAllNotes } from '../services/NotesService';
import environment from '../environment.json'
import './Notes.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Notes = () => {
   const history = useHistory();
    const [notes , setNotes] = useState([]);
  useEffect(() => {
    // Declare the async function inside useEffect
    const getAllNote = async () => {
      try {
        const response = await getAllNotes(`${environment.domain}notes/`);

        if (response.status === 200 || response.status === 201) {
          setNotes(response.data)
        } else {
          console.log("Response:", response);
        }

        // Navigate on successful fetch
      } catch (error) {
        console.error('Error fetching tests:', error.message);
      }
    };
    // Call the function
    getAllNote();
  }, []);
  
  const handleViewPdf = (name) =>{

    history.push('/pdfviewer/'+name)
  }



  return (
    <>
    <Navbar />
  
    <div className='notesMain'>
    <h2 className='text-primary my-3'>Notes</h2>
    <table class="table shadow-lg">
          <thead className='table-info'>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Date</th>
              <th scope="col">Name</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
           {
              notes && notes.map((item , index)=>{ return(
                <tr key={index} className='px-3'>
                <th scope="row">{index + 1}</th>
                <td>{item.upload_date.split('T')[0]}</td>
                <td>{item.notes_name}</td>


                <td><button onClick={()=>handleViewPdf(item.notes_name)}  className='btn btn-primary'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stickies" viewBox="0 0 16 16">
                    <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5V13a1 1 0 0 0 1 1V1.5a.5.5 0 0 1 .5-.5H14a1 1 0 0 0-1-1z" />
                    <path d="M3.5 2A1.5 1.5 0 0 0 2 3.5v11A1.5 1.5 0 0 0 3.5 16h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 16 9.586V3.5A1.5 1.5 0 0 0 14.5 2zM3 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5V9h-4.5A1.5 1.5 0 0 0 9 10.5V15H3.5a.5.5 0 0 1-.5-.5zm7 11.293V10.5a.5.5 0 0 1 .5-.5h4.293z" />
                  </svg>  View</button></td>
              </tr>)
              })
           } 
          </tbody>
        </table>
    </div>
    </>
  )
}

export default Notes