
  
  const getAllTests = async (url) => {
    try {
      const response = await fetch(url, {
        method: 'GET'
      });
  
      const responseData = await response.json(); // Parse JSON response
      return {status : response.status , data : responseData};
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };
  

  const getTypingTestById = async (url, id) => {
    try {
      const response = await fetch(url, {
        method: 'GET'
      });
  
      const responseData = await response.json(); // Parse JSON response
      return {status : response.status , data : responseData};
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };
  
  const getAllResults = async (url ) => {
    try {
      const response = await fetch(url, {
        method: 'GET'
      });
      
      const responseData = await response.json(); 
      console.log(responseData)// Parse JSON response
      return {status : response.status , data : responseData};
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };


  const getResultById = async (url) => {
    try {
      const response = await fetch(url, {
        method: 'GET'
      });
      const responseData = await response.json(); 
      console.log(responseData)// Parse JSON response
      return {status : response.status , data : responseData};
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };
  

  const compareResult = async (url) => {
    try {
      const response = await fetch(url, {
        method: 'GET'
      });
      const responseData = await response.json(); 
      console.log(responseData)// Parse JSON response
      return {status : response.status , data : responseData};
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };
  

  const submitTest = async (url, otherData) => {

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',// Assuming you're sending JSON data
        },
        body: JSON.stringify(otherData)     // Convert data to JSON string
      });
      const responseData = await response.json(); // Parse JSON response
      return {status : response.status , data : responseData};
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };



  const deleteResult = async(url)=>{
    try{
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',// Assuming you're sending JSON data
        },
     // Convert data to JSON string
      });
      const responseData = await response.json(); // Parse JSON response
      return {status : response.status , data : responseData};
    }catch(error){
      console.error('Error:', error);
      throw error; 
    }
  }

  // Export the service functions individually
  export {getAllTests, getTypingTestById  , getAllResults,submitTest , deleteResult,getResultById , compareResult};
