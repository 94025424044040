import { React, useEffect, useState } from 'react'
import { getAllResults } from '../services/TypingTestService';
import Navbar from '../components/navbar/Navbar';
import environment from '../environment.json'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import './Allresult.css'
const JsDiff = require('diff');


const AllResults = () => {
  const history = useHistory();
  const [allResult, setAllResult] = useState([]);
  let resultStr = "";


  // Calculate the total character excluding the spaces

  const user_id = localStorage.getItem("user_id");
  useEffect(() => {
    // Declare the async function inside useEffect
    const getAllResult = async () => {
      try {
        const response = await getAllResults(`${environment.domain}steno/getAllResult/` + user_id);

        if (response.status === 200 || response.status === 201) {
          // Assuming response.data is the correct path to your data

          const updatedData = response.data.map(item=>({
            ...item, 
            total_incorect_words : showResult(item.content, item.steno_content),
            exact_marks:((item.total_words - showResult(item.content, item.steno_content))/item.total_words * 100).toFixed(2)
          }))

          
      
          setAllResult(updatedData);
        } else {
          console.log("Response:", response);
        }
        // Navigate on successful fetch
      } catch (error) {
        console.error('Error fetching tests:', error.message);
      }
    };
    getAllResult();
  }, []);


  const showResult = (userInput,originalText) => {
    let wrongwordcount = -1;
    let correctWordChar = 0;
    let wrongWordChar =  0;
    let correctWords = 0;
    const salt = "Алексей";
    userInput += " " + salt;
    let baseArr = originalText.split(" ");
    let newArr = userInput.split(" ");
    let diff = JsDiff.diffWords(baseArr.join(" "), newArr.join(" "));
    let finalResultString = [];
    try{
        for (let i = 0; i < diff.length; i++) {
            // Normal case when no addition and removal
            if ( diff[i]["added"] === undefined && diff[i]["removed"] === undefined ){

                // Code to get the no error string let and excluding the spaces count.
                let trimmedValue = diff[i]['value'].trim();
                let length = trimmedValue === '' ? 0 : trimmedValue.split(' ').length;
                correctWordChar += trimmedValue.replace(/\s+/g, '').length;
                correctWords += length;

                finalResultString.push(
                    <span style={{color: "green"}}>
                        {diff[i]["value"]}
                    </span>
                );
            }
            // when there is just removale
            else if ( diff[i]['removed'] !== undefined && diff[i]["removed"] === true && diff[i]["value"] !== "\n") {
                // Case 1: remove and next one is add.
                
                if ( i+1 < diff.length && diff[i+1]["added"] !== undefined && diff[i+1]["added"] !==  false && diff[i+1]["value"] != salt){

                    let ith_wordCharCount = diff[i]['value'].trim().replace(/\s+/g, '').length;
                    let _ith_wordCharCount = diff[i+1] == salt ? (diff[i+1]['value'].trim().replace(/\s+/g, '').length):(0);
                    
                    wrongwordcount+=1;

                    wrongWordChar += (ith_wordCharCount + _ith_wordCharCount);
                    finalResultString.push(
                        <span style={{color: "red"}}>
                           {"{"} {diff[i+1]["value"]} {"}"}
                        </span>
                    );
                    finalResultString.push(
                        <span style={{color: "blue"}}>
                           {"{"} {diff[i]["value"]} {"}"}
                        </span>
                    );
                    i++;
                }
                // Case 2: just remove
                else{
                    wrongwordcount+=(diff[i]["value"].split(" ").length - 1)
                    finalResultString.push(
                        <span style={{color: "red"}}>
                            {diff[i]["value"]} 
                        </span>
                    );
                }
            }
            // Handling the add case
            else if ( diff[i]['added'] !== undefined && diff[i]["added"] === true && diff[i]["value"] !== "\n"){
                if (diff[i]["value"].trim().toLowerCase() != salt.trim().toLowerCase()){
                    let ith_wordCharCount = diff[i]['value'].trim().replace(/\s+/g, '').length;
                    wrongWordChar += (ith_wordCharCount);
                    wrongwordcount+=1;
                    // Case 1:
                    finalResultString.push(
                        <span style={{color: "red"}}>
                            {"{"} {diff[i]["value"]} {"}"}
                        </span>
                    );
                }
                
            }else{
                continue;
            }
            
        }
    }catch(err){
        finalResultString.push(
            <span style={{color: "red"}}>
                {"Error in finding the difference!!"}
            </span>
        );
    }
    wrongwordcount++;
    resultStr = (
        <span>
            {finalResultString}
        </span>
    );
    return wrongwordcount;
}
  
const handleShow = (id) =>{
  const data3 = allResult.filter(obj => obj.id === id);
const obj = data3.length > 0 ? data3[0] : null; // Get the first object or null if not found

const data2 = obj ? { ...obj, "typedtext": obj.content } : null; // Spread the properties of obj and add "typedtext"

console.log(data2);
    history.push("/show" , {data2});
}


const handleCompare = (id, steno_content) =>{

  history.push("/compare/"+id , {steno_content});
}

  return (
    <>
      <Navbar />
      <div className='table2'>
      <table class="table shadow-sm">
        <thead className='table-info'>
          <tr>
            <th scope="col">S.NO.</th>
            <th scope="col">DATE</th>
            <th scope="col">TEST NAME</th>
            <th scope="col">TOTAL WORDS</th>
            <th scope="col">TOTAL TYPED</th>
            <th scope="col">INCORRECT</th>
            <th scope="col">MARK</th>
            <th scope="col">ANSWER SHEET</th>
          </tr>
        </thead>
        <tbody>
          {
            allResult && allResult.map((item, index) => {
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{item.submitted_on.split('T')[0]}</td>
                  <td>{item.test_name}</td>
                  <td>{item.total_words}</td>
                  <td>{item.content.split(" ").length}</td>
                  <td>{item.total_incorect_words}</td>
                  <td>{item.exact_marks}</td>
                  <td className='d-flex'>
                    <button onClick={()=>handleShow(item.id)} className='btn btn-primary mx-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-menu-button-wide" viewBox="0 0 16 16">
                      <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h13A1.5 1.5 0 0 1 16 1.5v2A1.5 1.5 0 0 1 14.5 5h-13A1.5 1.5 0 0 1 0 3.5zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5z" />
                      <path d="M2 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m10.823.323-.396-.396A.25.25 0 0 1 12.604 2h.792a.25.25 0 0 1 .177.427l-.396.396a.25.25 0 0 1-.354 0M0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5" />
                    </svg> Show</button>
                    <button onClick={()=>handleCompare(item.test_id, item.steno_content)} className='btn btn-warning'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hypnotize" viewBox="0 0 16 16">
                      <path d="m7.949 7.998.006-.003.003.009zm.025-.028v-.03l.018.01zm0 .015.04-.022.01.006v.04l-.029.016-.021-.012zm.049.057v-.014l-.008.01zm-.05-.008h.006l-.006.004z" />
                      <path fill-rule="evenodd" d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M4.965 1.69a6.97 6.97 0 0 1 3.861-.642c.722.767 1.177 1.887 1.177 3.135 0 1.656-.802 3.088-1.965 3.766 1.263.24 2.655-.815 3.406-2.742.38-.975.537-2.023.492-2.996a7.03 7.03 0 0 1 2.488 3.003c-.303 1.01-1.046 1.966-2.128 2.59-1.44.832-3.09.85-4.26.173l.008.021.012-.006-.01.01c.42 1.218 2.032 1.9 4.08 1.586a7.4 7.4 0 0 0 2.856-1.081 6.96 6.96 0 0 1-1.358 3.662c-1.03.248-2.235.084-3.322-.544-1.433-.827-2.272-2.236-2.279-3.58l-.012-.003c-.845.972-.63 2.71.666 4.327a7.4 7.4 0 0 0 2.37 1.935 6.97 6.97 0 0 1-3.86.65c-.727-.767-1.186-1.892-1.186-3.146 0-1.658.804-3.091 1.969-3.768l-.002-.007c-1.266-.25-2.666.805-3.42 2.74a7.4 7.4 0 0 0-.49 3.012 7.03 7.03 0 0 1-2.49-3.018C1.87 9.757 2.613 8.8 3.696 8.174c1.438-.83 3.084-.85 4.253-.176l.005-.006C7.538 6.77 5.924 6.085 3.872 6.4c-1.04.16-2.03.55-2.853 1.08a6.96 6.96 0 0 1 1.372-3.667l-.002.003c1.025-.243 2.224-.078 3.306.547 1.43.826 2.269 2.23 2.28 3.573L8 7.941c.837-.974.62-2.706-.673-4.319a7.4 7.4 0 0 0-2.362-1.931Z" />
                    </svg> Compare</button>
                    </td>
                </tr>
              )
            })

          }
        </tbody>
      </table>
      </div>
    </>
  )
}

export default AllResults