const getAllNotes = async (url) => {
    try {
      const response = await fetch(url, {
        method: 'GET'
      });
  
      const responseData = await response.json(); // Parse JSON response
      return {status : response.status , data : responseData};
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };


  export {getAllNotes };