import React from 'react'
import Navbar from '../components/navbar/Navbar'
import MainFooter from '../components/footer/mainFooter/MainFooter'
import './Homepage.css'
import logo from '../images/logo/logo.jpg'
import poster from '../images/Janki Steno Home Page.png'
const HomePage = () => {

  
  return (
    <>
      <Navbar />
     
      {/* <marquee> <h2 className='my-3'>This is moving text Moving Right to Left</h2> </marquee> */}
      <div id="carouselExample" class="my-2 carousel slide">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src={poster} class="d-block w-100" alt="..." />
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>

      <div className='section1'>
        <h1>जानकी स्टेनो एवं टाइपिंग क्लासेज, बिहार</h1>
        <p>(स्टेनो एवं टाइपिंग हेतु सर्वश्रेष्ठ शिक्षण संस्थान)</p>
        <div className='innersection'>
          <div className='section11'>
            <img src={logo}/>
          </div>
          <div className='section12'>
            <h3  className='text-primary my-3'>स्टेनो एवं टाइपिंग प्रतियोगी परीक्षाओं की तैयारी हेतु सर्वश्रेष्ठ शिक्षण संस्थान</h3>
            <p style={{ textAlign:"justify"}}>"जानकी स्टेनो एवं टाइपिंग क्लासेज स्टेनो एवं टाइपिंग की प्रतियोगी परीक्षाओं की तैयारी हेतु सर्वश्रेष्ठ विश्वसनीय संस्थान है। जानकी स्टेनो ने अब तक स्टेनो एवं टाइपिंग के क्षेत्र में कई विद्यार्थियों को सफलता के शिखर तक पहुंचाने में सफल प्रयास किए हैं। साथ ही अपने ऑनलाइन प्लेटफार्म के माध्यम से स्टेनो के क्षेत्र में एक ऑनलाइन क्रांति लाकर दूर दराज तक विद्यार्थियों को सुविधाएं पहुंचाकर उन्हें सफलता का मार्ग दिखाया है। जानकी स्टेनो अपने निरंतर परिश्रम और प्रयास से विद्यार्थियों को उनकी मंजिल तक पहुंचाने में अपना योगदान देने में अग्रणी रहा है और सदैव रहेगा।"</p>
            <p>जानकी स्टेनो द्वारा दी जाने वाली सुविधाएं :-</p>
            <p>
            <br/> <span className='text-warning'>✓</span> कुशल एवं अनुभवी विशेषज्ञों द्वारा अध्यापन।
            <br/> <span className='text-warning'>✓</span> ऑनलाइन टेस्ट सीरीज द्वारा स्टेनो दक्षता मूल्यांकन।
            <br/> <span className='text-warning'>✓</span> ऑनलाइन लाइव क्लास द्वारा घर बैठे स्टेनो की बेहतर तैयारी।
            <br/> <span className='text-warning'>✓</span> pdf द्वारा आउटलाइन एवं नोट्स की सुविधा।


            </p>
          </div>
        </div>
      </div>


      <MainFooter />
    </>
  )
}

export default HomePage