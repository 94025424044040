import {React, useState , useEffect} from 'react'
import Navbar from '../components/navbar/Navbar'
import './Dashboard.css'
import { getWhatsappNumber } from '../services/PackageService'
import environment from '../environment.json';
import call from '../images/call.jpg'
import wt from '../images/wt.jpg'
import Footer from '../components/footer/Footer'
import tele from '../images/tele.jpg'
import yt from '../images/yt.jpg'
import { useHistory } from 'react-router-dom';


const Dashboard = () => {
  const [flag , setFlag] = useState(false);
  const [number , setNumber] = useState();
  const [wnumber , setWhatsappNumber] = useState();

  const history = useHistory();
  useEffect(() => {
    // Declare the async function inside useEffect
    const getWhatsappNmber = async () => {
      try {
        const response = await getWhatsappNumber(`${environment.domain}tests/getWhatsappNumber`);

        if (response.status === 200 || response.status === 201) {
          setWhatsappNumber(response.data[0].whatsappnumber); // Assuming response.data is the correct path to your data
          setNumber(response.data[0].phonenumber);
          
        } else {
          
        }

        // Navigate on successful fetch
      } catch (error) {
        console.error('Error fetching tests:', error.message);
      }
    };
    // Call the function
    getWhatsappNmber();
  }, []);

  
  const openDialer = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleYtClick = ()=>{
    history.push("https://youtube.com/@jankistenotypingclasses?si=CSpKIVJsrLRAfBoB")
    window.location.href = 'https://www.youtube.com/watch?v=your-video-id';

  }
  
  const handleTelegramClick = () => {
    // Open Telegram desktop app
    window.open('https://t.me/+AWbZcSnT5eg2ODVl');
  };

  const openWhatsApp = () => {
    // Phone number to chat with (replace with your desired phone number)
    const phoneNumber = wnumber;
    // Message to be sent (optional, replace with your desired message)
    const message = ``;

    // Construct the WhatsApp URL
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    // Navigate to WhatsApp chat link
    window.open(whatsappURL);
  };
  useEffect(() => {
    // Declare the async function inside useEffect
    if (localStorage.getItem("package_id") === "0") {
      setFlag(true);
    }
  }, []);
  return (
    <>
      <Navbar />
      <div className='main1'>
      { flag ? (<div className='alert alert-danger alert alert-dismissible fade show' role="alert">
                        Your Package is expired Please contact to renew it.
                        <button onClick={()=>setFlag(false)} type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div> ):('')
            } 
            <div style={{"display":"flex", "justifyContent":"space-between", "cursor":"pointer"}}>
        <h3 className='text-primary'>Dashboard</h3>
        <div>
        { number ? (
                    <img width={40} height={40} onClick={openWhatsApp} className='mx-2 rounded-circle border border-success' src={wt} /> 
                  ):('')    
                }

                <img width={40} height={40} onClick={handleTelegramClick} className='mx-2 rounded-circle border border-success' src={tele} /> 



                { wnumber ? (
                    <img width={40} height={40} onClick={() => openDialer(number)} className='mx-2 rounded-circle border border-success' src={call} />
                ):('')

                


                }


              <img width={40} height={40} onClick={handleYtClick} className='rounded-circle border border-danger' src={yt} />
                </div>
                </div>
      <div className='dmain'>
        <h3 className='shadow-sm bg-primary text-light'>वेबसाइट पोर्टल से संबंधित विशेष आवश्यक सूचना एवं दिशा निर्देश</h3>
        <div className='dmain2 text-primary'>
           <br/> ✓  डिक्टेशन का अनुवाद करते समय आपको ऐसे शब्दों में कोडिंग का प्रयोग करना है, जिनके शुद्ध रूप में कोडिंग का प्रयोग करना अनिवार्य है। जैसे:- क्र (0216), कृ (Alt+209), ऊ (Alt+0197), त्त् (वित्त) (Alt+0217), फ्र (Alt+0221), (शृंखला)(Alt+220), (ट्र) (Alt+0170) इत्यादि।

          <br/> ✓  हिन्‍दी (मंगल रेमिंगटन गेल) :- डिक्‍टेशन का अनुवाद करते समय आपको ऐसे शब्‍दों में कोडिंग का प्रयोग करना है, जिनके शुद्ध रुप में कोडिंग का प्रयोग करना अनिवार्य है।.
           <br/>जैसे - प्रश्‍नवाचक (?)-  (Alt+063), अर्द्धविराम(;)-(Alt+059),  पूर्ण विराम - (Shift+1), दीर्घ (ऊ)-Shift,  त्र (Shift+9), द्ध (Shift + 8), ऋ(Shift +0) ….. इत्‍यादि
          
           <br/> ✓  डिक्टेशन को ध्यानपूर्वक सुनकर पोर्टल पर उसका अनुवाद करें।

           <br/> ✓ पोर्टल का उपयोग करते समय अपने कम्प्यूटर या लैपटाॅप को इंटरनेट से कनेक्ट रखें।

           <br/> ✓ अनुवाद करने के बाद आपकी काॅपी स्वतः चेक होकर आ जाएगी। तत्पश्चात् आप अपनी काॅपी को देख सकते हैं।

           <br/> ✓ टेस्ट पूरी तरह सबमिट हो जाने के बाद सीधे होम option पर क्लिक करें, उसके बाद दूसरे टेस्ट का अनुवाद प्रारंभ करें।

           <br/> ✓  आशुलिपि सीखने वाले और आशुलिपि भर्ती परीक्षा की तैयारी करने वाले सभी विद्यार्थियों के लिए यह पोर्टल बहुत ही महत्वपूर्ण है।

           <br/> ✓  इस पोर्टल का उद्देश्य आपकी स्टेनो की दक्षता बेहतर बनाना है।

           <br/> ✓  हम किसी भी भर्ती परीक्षा, भर्ती परीक्षा के आयोजनकर्ता, विभाग, संस्था, निकाय या अन्य कोई भी भर्ती एजेंसी या आयोग इत्यादि से अधिकृत होने का दावा नहीं करते हैं।

           <br/> ✓  इस पोर्टल पर आपको सभी विषयों पर आधारित डिक्टेशन उपलब्ध होंगे, जिसमें आपको सम-सामयिक मुद्दों और दैनिक जीवन से जुड़े मुद्दों पर आधारित डिक्टेशन होंगे।

           <br/> ✓  उक्त सभी डिक्टेशन सामग्री को हम हमारे द्वारा प्रकाशित या सृजित की होने का दावा नहीं करते हैं।

           <br/> ✓  यह पोर्टल किसी भी भर्ती परीक्षा के पोर्टल से हू-ब-हू होने का दावा नहीं करता है। इस पोर्टल का उद्देश्य सभी आशुलिपिकों को परीक्षा की तरह माहौल उपलब्ध कराना मात्र है।

           <br/> ✓  इस पोर्टल पर डिक्टेशन में प्रयुक्त की गई हिंदी शुद्ध, व्यावहारिक तथा मानक हिंदी पर आधारित है। इस पोर्टल पर उपलब्ध डिक्टेशन में भारत सरकार द्वारा अधिकृत हिंदी के मानकीकरण से संबंधित नियमों को ध्यान में रखते हुए हिंदी का प्रयोग किया गया है।
        </div>
      </div>
      </div>
      <Footer/>
    </>
  )
}

export default Dashboard