import './App.css';
import AuthenticationPage from './pages/AuthenticationPage';
import HomePage from './pages/HomePage';
import { BrowserRouter as Router, Route , Switch} from 'react-router-dom';
import TypingPage from './pages/typing/TypingPage';
import StenoPage from './pages/steno/StenoPage';
import TypingTestPage from './pages/typing/TypingTestPage';
import StenoAudioPage from './pages/steno/StenoAudioPage';
import StenoTestPage from './pages/steno/StenoTestPage';
import Packages from './pages/PackagesPage';
import Dashboard from './pages/Dashboard';
import Resultpage from './pages/Resultpage';
import ShowResult from './pages/AllResults';
import ProfilePage from './pages/profile/ProfilePage';
import ProtectedRoute from './components/ProtectedRoute'
import ShowPdf from './pages/ShowPdf';
import StenoResultPage from './pages/steno/result/StenoResultPage';
import Comapare from './pages/compare/Comapare';
import Notes from './pages/Notes'

function App() {

  return (
    <>
      <Router>
  <Switch>
    <Route path="/" exact component={HomePage} />
    <ProtectedRoute path="/dashboard" exact component={Dashboard} />
    <ProtectedRoute path="/profile" exact component={ProfilePage} />
    <Route path="/login" exact component={AuthenticationPage} />
    <ProtectedRoute path="/typing" exactharsh component={TypingPage} />
    <ProtectedRoute path="/steno" exact component={StenoPage} />
    <ProtectedRoute path="/typingTest/:id" exact component={TypingTestPage} />
    <ProtectedRoute path="/stenoAudio/" exact component={StenoAudioPage} />
    <ProtectedRoute path="/stenoTest" exact component={StenoTestPage} />
    <ProtectedRoute path="/packages" exact component={Packages} />
    <ProtectedRoute path="/allResult" exact component={ShowResult} />
    <ProtectedRoute path="/stenoResult" exact component={StenoResultPage} />
    <ProtectedRoute path="/notes" exact component={Notes} />
    <ProtectedRoute path="/pdfviewer/:noteName" exact component={ShowPdf} />
    <ProtectedRoute path="/show" exact component={StenoResultPage} />
    <ProtectedRoute path="/compare/:id" exact component={Comapare} />
    <ProtectedRoute path="/result" exact component={Resultpage} />
    {/* Add more protected routes here */}
  </Switch>
</Router>

      </>
  );
}

export default App;
