import { React, useState, useEffect } from 'react'
import Navbar from '../../components/navbar/Navbar'
import './css/Steno.css'
import { useHistory } from 'react-router-dom'
import { getAllResults, getAllTests, deleteResult,getResultById } from '../../services/StenoService'
import environment from '../../environment.json'
const StenoPage = () => {
  const history = useHistory();
  const handleStartTest = async(id, time) => {
    
    if (time === 2) {
      const confirmDelete = window.confirm("Your previous result was deleted. Do you want to continue ?");
  
    if (confirmDelete) {
      try {
        const response = await deleteResult(`${environment.domain}steno/deleteResult/`+id);
        if (response.status === 200 || response.status === 201) {
          const dataToSent = tests.find(obj => obj.steno_test_id === id);
          history.push("/stenoAudio", { dataToSent });
        } else {
          console.log("Response:", response);
        }
      } catch (error) {
        console.error('Error fetching tests:', error.message);
      }
    }

    }else{
      const dataToSent = tests.find(obj => obj.steno_test_id === id);
      history.push("/stenoAudio", { dataToSent });
    }


  };

  let [tests, setTests] = useState([]);
  let [testsRetain, setTestRetain] = useState([]);
  let [allresults, setAllResults] = useState([]);
  const [isGiven, setIsGiven] = useState(false);

  useEffect(() => {
    const getTests = async () => {
      try {
        const response = await getAllTests(`${environment.domain}steno/all/stenoTests`);
        if (response.status === 200 || response.status === 201) {
          if (localStorage.getItem("package_id") === "0" || localStorage.getItem("package_id") === "5" || localStorage.getItem("package_id") === "6" ) {
            setTestRetain(response.data.filter(obj => obj.visibility === "Free" || obj.visibility === "free"));
            setTests(response.data.filter(obj => obj.visibility === "Free" || obj.visibility === "free"));
          } else if(localStorage.getItem("package_id") === "1" || localStorage.getItem("package_id") === "2" ||localStorage.getItem("package_id") === "3" || localStorage.getItem("package_id") === "4" ){
            setTests(response.data);
            setTestRetain(response.data);
          }
          
        } else {
          console.log("Response:", response);
        }
      } catch (error) {
        console.error('Error fetching tests:', error.message);
      }
    };

    getTests();
  }, []); // Add resultid as a dependency

  useEffect(() => {
    const getReult = async () => {
      try {
        const response = await getAllResults(`${environment.domain}steno/getAllResult/` + localStorage.getItem("user_id"));
        if (response.status === 200 || response.status === 201) {
          if (localStorage.getItem("package_id") === "0" || localStorage.getItem("package_id") === "5" || localStorage.getItem("package_id") === "6") {
            setAllResults(response.data)
          } else {
            setAllResults(response.data)
          }

        } else {
          console.log("Response:", response);
        }
      } catch (error) {
        console.error('Error fetching tests:', error.message);
      }
    };

    getReult();
  }, []); // Add resultid as a dependency
  const filterTests = (type) => {
    tests = testsRetain;
    if (type === "Hindi") {
      const test = tests.filter(obj => obj.language === "Hindi");
      setTests(test);
    } else if (type === "English") {
      const test = tests.filter(obj => obj.language === "English");
      setTests(test);
    } else if (type === "Mangal") {
      const test = tests.filter(obj => obj.type === "Mangal");
      setTests(test);
    } else {
      setTests(tests);
    }
  };

  const handleAnswerSheet = async(test_id,time) =>{
    
    
    try {
      const response = await getAllTests(`${environment.domain}steno/getResultById/`+localStorage.getItem("user_id")+`/`+test_id);
      if (response.status === 200 || response.status === 201) {
        const data = response.data[0];
        const data2 = {...data , "typedtext": data.content}
       history.push("/stenoResult", {data2})
      } else {
        console.log("Response:", response);
      }
    } catch (error) {
      console.error('Error fetching tests:', error.message);
    }


  }


  return (
    <>
      <Navbar />
      <div className='container1'>

        <h3 className='my-3 mx-3'>Steno Tests</h3>
        <div className='buttons d-flex '>
  
          <button onClick={() => filterTests("All")} className='col-sm-1 mx-3 btn btn-outline-secondary'>All</button>
          <button onClick={() => filterTests("Hindi")} className=' col-sm-1 mx-3 btn btn-outline-primary'>Hindi</button>
          <button onClick={() => filterTests("English")} className='col-sm-1 mx-3 btn btn-outline-success'>English</button>
          <button onClick={() => filterTests("Mangal")} className=' col-sm-2 btn btn-outline-danger'>Hindi (Mangal)</button>
        
        </div>
      <div className='tableDiv my-3'>
        <table class="table shadow-lg">
          <thead className='table-info'>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Date</th>
              <th scope="col">Test Name</th>
              <th scope="col">Test Language</th>
              <th scope="col">Dictation Speed</th>
              <th scope="col">Total Words</th>
              <th scope="col">Duration</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {tests && tests.map((item, index) => {
              return (
                <tr key={index} className='px-3'>
                  <th scope="row">{index + 1}</th>
                  <td>{item.date.split('T')[0]}</td>
                  <td>{item.test_name}</td>
                  <td>{item.language} {item.type === "Mangal" ? ("(Mangal)") : ("")}</td>
                  <td>{item.dictation_speed} WPM</td>
                  <td>{item.total_words} Words</td>
                  <td>{item.duration} Mins</td>
                  <td>{allresults && allresults.find(obj => obj.test_id == item.steno_test_id) ? (<><button onClick={() => handleAnswerSheet(item.steno_test_id)} className='btn btn-primary'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-menu-button-wide" viewBox="0 0 16 16">
                    <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h13A1.5 1.5 0 0 1 16 1.5v2A1.5 1.5 0 0 1 14.5 5h-13A1.5 1.5 0 0 1 0 3.5zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5z" />
                    <path d="M2 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m10.823.323-.396-.396A.25.25 0 0 1 12.604 2h.792a.25.25 0 0 1 .177.427l-.396.396a.25.25 0 0 1-.354 0M0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5" />
                  </svg> Answer Sheet</button> &nbsp;<button onClick={() => handleStartTest(item.steno_test_id,2)} className='btn btn-warning'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                  </svg> Restart</button></>) : (<button onClick={() => handleStartTest(item.steno_test_id,1)} className='btn btn-success'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-play" viewBox="0 0 16 16">
                    <path d="M10.804 8 5 4.633v6.734zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696z" />
                  </svg> Start Test</button>)}</td>
                </tr>

              )
            })

            }

          </tbody>
        </table>
        </div>
      </div>
    </>
  )
}

export default StenoPage