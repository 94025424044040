import React, { useState, useEffect } from 'react';
import Navbar from '../../components/navbar/Navbar';
import './css/stenotest.css';
import { useParams } from 'react-router-dom';
import { submitTest } from '../../services/StenoService';
import { useHistory , useLocation} from 'react-router-dom/cjs/react-router-dom.min';
import environment from '../../environment.json'
// import {Convert_to_Kritidev_010} from '../../components/extras/Kd'

const TypingTestPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { data } = location.state;
  let { id } = useParams();
  const [fontSize, setFontSize] = useState(16); // Initial font size
  const [isTextAreaEnabled, setTextAreaEnabled] = useState(false);
  const selectedTime = data.duration * 60; // Initial time in seconds
  const [timeLeft, setTimeLeft] = useState(selectedTime);
  const [timerRunning, setTimerRunning] = useState(true);
  const [count, setCount] = useState(0);
  const [typedText, setTypedText] = useState('');
  const [originalText, setOriginalText] = useState('');
  const [isStartClicked, setStartClicked] = useState(false);
  const [inputType, setInputType] = useState("");
  const [onbackSpace , setBackSpace] = useState(false);
  const [stenoTest, setStenoTest] = useState({});
  const [effectKey, setEffectKey] = useState(1);
  const [typingType, setTypingType] = useState();

  const[backOn, setBackOn] = useState(false)

useEffect(()=>{
  console.log(data)
   if (data.language === "Hindi" && data.type === "Mangal") {
     setTypingType("mangal");
   }else if (data.language === "Hindi" && data.type === "Hindi") {
    setTypingType("kurtidev");
   }
}, [])



useEffect(() => {
  const handleBackspace = event => {
    if (backOn === false) {
      if (event.keyCode === 8) {
        setCount(prevCount => prevCount + 1);
      }
    }
  
  };

  document.addEventListener('keydown', handleBackspace);
  return () => {
    document.removeEventListener('keydown', handleBackspace);
  };
}, [backOn, effectKey]);

useEffect(() => {
  const inputElement = document.getElementById('backSpaceSwitch');

  if (inputElement) {
    const handleBackspaceKey = (event) => {
      if (event.key === 'Backspace') {
        const condition = true; // Replace with your actual condition

        if (condition) {
          event.preventDefault();
        }
      }
    };

    const listenBackSpace = (switchState) => {
      inputElement.removeEventListener('keydown', handleBackspaceKey);

      if (switchState) {
        inputElement.addEventListener('keydown', handleBackspaceKey);
      }
    };

    listenBackSpace(backOn);
  } else {
    console.error('Input element not found');
  }
}, [backOn,effectKey]);

const handleSwitchClick = () => {
  setBackOn(!backOn);
  setEffectKey(prevKey => prevKey + 1);
};


const handleSubmit = async() => {
  let time = selectedTime - timeLeft;
  const otherData = {
    duration: time.toString(),
    selectedTime: selectedTime.toString(),
    backspace: count.toString(),
    user_id: localStorage.getItem("user_id"),
    content: typedText,
    test_id: data.steno_test_id,
  };

  if(inputType === "kurtidev"){
    try {
      let data2 = {
        "text": typedText,
        "format": "json"
      };
      const response2 = await fetch("https://hindi-font-converter.vercel.app/api/krutidev-to-unicode", {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data2)
      });
  
      const responseData2 = await response2.json(); // Parse JSON response
       const otherData2 = {...otherData, "content":responseData2.data.output_text};
    const response = await submitTest(`${environment.domain}steno/submitTest/`, otherData2);
    if (response.status === 200 || response.status === 201) {
      const data2 = {...data, "typedtext": responseData2.data.output_text, "submitted_on": new Date().toLocaleString()}
      history.push("/stenoResult", {data2});
    } else {
      console.log("Response:", response);
    }
  } catch (error) {
    console.error('Error fetching tests:', error.message);
  }
  }else{
    try {
  
       const otherData2 = {...otherData, "content":typedText};
    const response = await submitTest(`${environment.domain}steno/submitTest/`, otherData2);
    if (response.status === 200 || response.status === 201) {
      const data2 = {...data, "typedtext": typedText, "submitted_on": new Date().toLocaleString()}
      history.push("/stenoResult", {data2});
    } else {
      console.log("Response:", response);
    }
  } catch (error) {
    console.error('Error fetching tests:', error.message);
  }
  
  }
};

  const handleChange = event => {
    // Convert_to_Kritidev_010();
    setTypedText(event.target.value);
  };

  useEffect(() => {

    if (data.language === "Hindi" && data.type === "Hindi") {
      setInputType("kurtidev")
    }

    const handleBackspace = event => {
      if (event.keyCode === 8) {
        setCount(prevCount => prevCount + 1);
      }
    };

    document.addEventListener('keydown', handleBackspace);
    return () => {
      document.removeEventListener('keydown', handleBackspace);
    };
  }, []);

  useEffect(() => {
    let interval;
    if (timerRunning && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      clearInterval(interval);
      setTextAreaEnabled(false);
      handleSubmit(); // Call submit function when countdown ends
    }
    return () => clearInterval(interval);
  }, [timerRunning, timeLeft]); // Add timerRunning and timeLeft as dependencies



  
  return (
    <>
      <Navbar />
      
      <div className='container my-3'>
        <div className='testDetails'>
          <h4 className='text-primary'>{data.test_name}</h4>
          <div>Time Left: {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')} minutes</div>
          <p>Backspace: <span>{count}</span></p>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox"  onClick={handleSwitchClick} role="switch" id="flexSwitchCheckChecked"/>
            <label class="form-check-label" for="flexSwitchCheckChecked">Checked switch checkbox input</label>
          </div>
        </div>
        <div className='para2 my-3'>
          <textarea
            cols={50}
            id='backSpaceSwitch unicode_text'
            placeholder=''
            className={`${inputType}`}
            rows={3}
            value={typedText}
            onChange={handleChange}
            spellCheck="false"
          />
        </div>
        <button onClick={handleSubmit} className='btn submitBtn btn-primary'>Submit</button>
      </div>
    </>
  );
};

export default TypingTestPage;
