import React, { useState } from 'react'
import './Result.css'
const JsDiff = require('diff');

const Result = ({ userInput , originalText , otherData , type}) => {

    let resultStr = "";
    let wrongwordcount = -1;
    let correctWordChar = 0;
    let wrongWordChar =  0;
    let correctWords = 0;

    // Calculate the total character excluding the spaces
    let totalCharactersTyped = userInput.trim().replace(/\s+/g, '').length;
    let convertUserInput = userInput.split(" ");
    let originalTextArray  = originalText.split(" ");

    const showResult = () => {
        const salt = "Алексей";
        userInput += " " + salt;
        let baseArr = originalText.split(" ");
        let newArr = userInput.split(" ");
        let diff = JsDiff.diffWords(baseArr.join(" "), newArr.join(" "));
        let finalResultString = [];
        try{
            for (let i = 0; i < diff.length; i++) {
                // Normal case when no addition and removal
                if ( diff[i]["added"] === undefined && diff[i]["removed"] === undefined ){

                    // Code to get the no error string let and excluding the spaces count.
                    let trimmedValue = diff[i]['value'].trim();
                    let length = trimmedValue === '' ? 0 : trimmedValue.split(' ').length;
                    correctWordChar += trimmedValue.replace(/\s+/g, '').length;
                    correctWords += length;

                    finalResultString.push(
                        <span style={{color: "green"}}>
                            {diff[i]["value"]}
                        </span>
                    );
                }
                // when there is just removale
                else if ( diff[i]['removed'] !== undefined && diff[i]["removed"] === true && diff[i]["value"] !== "\n") {
                    // Case 1: remove and next one is add.
                    
                    if ( i+1 < diff.length && diff[i+1]["added"] !== undefined && diff[i+1]["added"] !==  false && diff[i+1]["value"] != salt){

                        let ith_wordCharCount = diff[i]['value'].trim().replace(/\s+/g, '').length;
                        let _ith_wordCharCount = diff[i+1] == salt ? (diff[i+1]['value'].trim().replace(/\s+/g, '').length):(0);
                        
                        wrongwordcount+=1;
                        console.log(">>>>>>>>>"+wrongwordcount);
                        wrongWordChar += (ith_wordCharCount + _ith_wordCharCount);
                        finalResultString.push(
                            <span style={{color: "red"}}>
                               {"{"} {diff[i+1]["value"]} {"}"}
                            </span>
                        );
                        finalResultString.push(
                            <span style={{color: "blue"}}>
                               {"{"} {diff[i]["value"]} {"}"}
                            </span>
                        );
                        i++;
                    }
                    // Case 2: just remove
                    else{
                        // console.log(diff[i]["value"] +">>>>>>>>>>>>>>>>"+ diff[i]["value"].split(" ").length)
                        wrongwordcount+=1
                        finalResultString.push(
                            <span style={{color: "red"}}>
                                {diff[i]["value"]} 
                            </span>
                        );
                       
                    }
                }
                // Handling the add case
                else if ( diff[i]['added'] !== undefined && diff[i]["added"] === true && diff[i]["value"] !== "\n" ){
                    if (diff[i]["value"].trim().toLowerCase() != salt.trim().toLowerCase()) {
                        let ith_wordCharCount = diff[i]['value'].trim().replace(/\s+/g, '').length;
                        wrongWordChar += (ith_wordCharCount);
                        wrongwordcount+=1;
                        console.log(">>>>>>>"+wrongwordcount);

                        // Case 1:
                        finalResultString.push(
                            <span style={{color: "red"}}>
                                {"{"} {diff[i]["value"]} {"}"}
                            </span>
                        );
                    }
                    
                }else{
                    continue;
                }
                
            }
        }catch(err){
            finalResultString.push(
                <span style={{color: "red"}}>
                    {"Error in finding the difference!!"}
                </span>
            );
        }
        
    
        resultStr = (
            <span>
                {finalResultString}
            </span>
        );
    }
    

    showResult();

  return (
    <>
        
    <div class="main my-3 shadow-lg">
 

 
{ type === "typing" ? (<>

        <div class="resultStats shadow-sm">
         <div class="text-light row">
            <p className='col-md-3'>Total Words : {originalTextArray.length}</p>
            <p className='col-md-3'>Skipped Words : {originalTextArray.length - convertUserInput.length}</p>
            <p className='col-md-3'>Total Backspace pressed : {otherData.backSpace} Times</p>
            <p className='col-md-3'>Time Allotted : {otherData.time_allotted/60} Mins</p>
            <p className='col-md-3'>Time Taken : {Math.floor(otherData.timeTaken/60) === 0 ? (otherData.timeTaken + " Sec"):(Math.floor(otherData.timeTaken/60) + " Mins" + (otherData.timeTaken%60) + "Sec")} </p>
         </div>
         <h6 className='text-left' style={{fontWeight:"600"}}>Method 1 : (One Word = 5 characters or key strokes)</h6>
         <div class="text-light row">
            <p className='col-md-3'>Total Characters Typed : {totalCharactersTyped} ({Math.floor(totalCharactersTyped/5)} Words)</p>
            <p className='col-md-3'>Correct Characters : {correctWordChar} ({Math.floor(correctWordChar/5)} Words)</p>
            <p className='col-md-3'>Wrong Characters : {wrongWordChar} ({Math.floor((wrongWordChar)/5)} Words)</p>
            <p className='col-md-3'>Accuracy : {((correctWordChar/(correctWordChar + wrongWordChar))*100).toFixed(2)}%</p>
         </div>
         <p> <span  className='text-light mx-3'> Gross Speed : {((Math.floor((correctWordChar + wrongWordChar)/5))/(otherData.timeTaken/60)).toFixed(2)} WPM  </span><span className='text-light mx-3'>Net Speed : {((Math.floor(correctWordChar/5)/(otherData.timeTaken/60))).toFixed(2)} WPM</span></p>

         <h6 style={{fontWeight:"600"}}>Method 2 : (One Word = Group of Letters Separated by Space)</h6>
         <div class="text-light row">
            <p className='col-md-3'>Words Typed : {correctWords + wrongwordcount} Words</p>
            <p className='col-md-3'>Wrong Words : {wrongwordcount == -1 ? 0 : wrongwordcount} Words</p>
            <p className='col-md-3'>Correct Words : {correctWords} </p>
            <p className='col-md-3'>Accuracy : {((((correctWords + wrongwordcount) - ((correctWords + wrongwordcount) - correctWords))/(correctWords + wrongwordcount))*100).toFixed(2)}%</p>
         </div>
         <p> <span className='text-light mx-3'> Gross Speed : {((convertUserInput.length)/(otherData.timeTaken/60)).toFixed(2)} WPM </span>     <span className='text-light mx-3'>Net Speed : {(correctWords/(otherData.timeTaken/60)).toFixed(2)} WPM</span></p>

        </div>
         </>):('')
}
         
         
         
       
        <p className=''>{resultStr && resultStr }</p>
    </div>
    
    
    </>
  )
}

export default Result