import React from 'react'
import AudioPlayer from '../../components/AudioPlayer'
import Navbar from '../../components/navbar/Navbar'
import StenoCss from  './css/stenotest.module.css'
import { useLocation } from 'react-router-dom';
// import audioFile from '../../audio/test1.mp3'

const StenoAudioPage = () => {
  
  const location = useLocation();
  const { dataToSent } = location.state;

  return (
   <>
   <Navbar />
   <div className={`${StenoCss.main} my-5`}> 
    <h2>{dataToSent.test_name}</h2>
    <h2 className={`${StenoCss.colorRed} `}>-:Instructions:-</h2>
    <h4 className={`${StenoCss.colorRed} `}>Do not refresh your page while listening audio <br/>
       You will be auto redirect to typing section after duration ends.</h4> 
     <br/><br/><br/>
      <h2>Listen to an Audio File</h2>
      <AudioPlayer data={dataToSent} src={`/audio/${dataToSent.audio_file}`} />
      {/* <AudioPlayer data={dataToSent} src={audioFile} /> */}
    </div>
   </>
  )
}

export default StenoAudioPage