import {React , useEffect, useState} from 'react'
import Navbar from '../../components/navbar/Navbar'
import './Profile.css'
import Footer from '../../components/footer/Footer'
import environment from '../../environment.json'
import { getUserById, updatePassword } from '../../services/AuthService'
const ProfilePage = () => {
  // http://localhost:3001/api/users/1
  const id = localStorage.getItem("user_id")
  const [profile , setProfile] = useState({});
  const [password , setPassword] = useState("");
  const [cpassword , setCpassword] = useState("");

  useEffect(() => {
    // Declare the async function inside useEffect
    const getProfile = async () => {
      try {
        const response = await getUserById(`${environment.domain}users/`+id);

        if (response.status === 200 || response.status === 201) {
          setProfile(response.data);
        } else {
          console.log("Response:", response);
        }

        // Navigate on successful fetch
      } catch (error) {
        console.error('Error fetching tests:', error.message);
      }
    };

    // Call the function
    getProfile();
  }, []); 


const [toast , setToast] = useState({type:"", message:""})
const [flag, setFlag] = useState(false);
const handleChangePassword = async() =>{

      if (password !== cpassword) {
        alert("Password and Confirm Password must be same")
      }else{
        const data = 
          {
            "email":localStorage.getItem("email"),
            "password":password
          }
        
        try {
          const response = await updatePassword(`${environment.domain}auth/changePassword/`,data );
          
          if (response.status === 200 || response.status === 201) {
            setFlag(true);
            setToast({type:"btn btn-success" , message:response.data.message})
          } else {
            setFlag(true);
            setToast({type:"btn btn-danger" , message:response.data.message})
          }
  
          // Navigate on successful fetch
        } catch (error) {
          console.error('Error fetching tests:', error.message);
        }
      };
      }


    console.log(profile)











  return (
    <>
    <Navbar/>
    { flag ? (<div class={`${toast.type} my-3alert alert-dismissible fade show`} role="alert">
                        {toast.message}
                        <button onClick={()=>setFlag(false)} type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div> ):('')
            } 


    <div className='mainProfile'>
    <h4 className='text-primary'>Profile</h4 >
    <div className='mainProfile2'>
     <div className='header'>
      <button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#exampleModal">Change Password</button>
      <p>Your Roll number : {profile && profile.roll_no}</p>
     </div>

     <div className='my-2 profileContentHeader'>
     <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
        <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
      </svg>
      <h4>{profile && profile.name}</h4>
      <p>{profile && profile.phone}</p>
      <p>Account Type : {profile ? (profile.package !== 0 ? "Paid" : "Pending") : "Pending"}
    </p>
     </div>
    </div>

    </div>







<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Change Password</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <label for="inputPassword5" class="form-label">Password</label>
<input onChange={(e)=>setPassword(e.target.value)} value={password} type="password" id="inputPassword5" class="form-control" aria-describedby="passwordHelpBlock"/>


<label for="inputPassword5" class="form-label">Confirm Password</label>
<input onChange={(e)=>setCpassword(e.target.value)} value={cpassword} type="text" id="inputPassword5" class="form-control" aria-describedby="passwordHelpBlock"/>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" onClick={handleChangePassword} class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>


    </>

  )
}

export default ProfilePage