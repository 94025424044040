import React, { useState, useEffect } from 'react';
import Navbar from '../../components/navbar/Navbar';
import './css/TypingTest.css';
import { useParams } from 'react-router-dom';
import { getTypingTestById, submitTest } from '../../services/TypingTestService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import environment from '../../environment.json'

const TypingTestPage = () => {
  const history = useHistory();
  let { id } = useParams();
  const [fontSize, setFontSize] = useState(25); // Initial font size
  const [isTextAreaEnabled, setTextAreaEnabled] = useState(false);
  const [selectedTime, setSelectedTime] = useState(60); // Initial time in seconds
  const [timeLeft, setTimeLeft] = useState(selectedTime);
  const [timerRunning, setTimerRunning] = useState(false);
  const [count, setCount] = useState(0);
  const [typedText, setTypedText] = useState('');
  const [originalText, setOriginalText] = useState('');
  const [isStartClicked, setStartClicked] = useState(false);
  const [inputType, setInputType] = useState("");
  const [onbackSpace , setBackSpace] = useState(false);
  const [effectKey, setEffectKey] = useState(1);

  const[backOn, setBackOn] = useState(false)

  useEffect(() => {
    let interval;
    if (timerRunning && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      clearInterval(interval);
      setTextAreaEnabled(false);
      handleSubmit(); // Call submit function when countdown ends
    }
    return () => clearInterval(interval);
  }, [timerRunning, timeLeft]);

  const handleSubmit = async() => {


       let time = selectedTime - timeLeft;

       if (inputType === "kurtidev") {
        try {
          let data2 = {
            "text": typedText,
            "format": "json"
          };
          const response = await fetch("https://hindi-font-converter.vercel.app/api/krutidev-to-unicode", {
            method: 'post',
            maxBodyLength: Infinity,
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data2)
          });
      
          const responseData = await response.json(); // Parse JSON response
          
          let data = {...typingTest , 
            "timeTaken":time, 
            "selectedTime":selectedTime,
            "backSpace":count,
            "result_content":responseData.data.output_text,
            "time_allotted":selectedTime
          }
          console.log(typedText)
              history.push("/result" , {data});
        } catch (error) {
          console.error('Error:', error);
          throw error;
        }
       } else {
        try {
         
          
          let data = {...typingTest , 
            "timeTaken":time, 
            "selectedTime":selectedTime,
            "backSpace":count,
            "result_content":typedText,
            "time_allotted":selectedTime
          }
              history.push("/result" , {data});
        } catch (error) {
          console.error('Error:', error);
          throw error;
        }
       }
         
  };

  const handleChange = event => {
    setTypedText(event.target.value);
  };

  const handleStartClick = () => {
    setTextAreaEnabled(true);
    setTimerRunning(true);
    setStartClicked(true);
  };

  const handleTimeChange = event => {
    setSelectedTime(parseInt(event.target.value));
    setTimeLeft(parseInt(event.target.value));
  };

  const increaseFontSize = () => {
    setFontSize(prevSize => prevSize + 2);
  };

  const decreaseFontSize = () => {
    setFontSize(prevSize => Math.max(prevSize - 2, 8));
  };

  const formatTime = time => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  useEffect(() => {
    const handleBackspace = event => {
      if (backOn === false) {
        if (event.keyCode === 8) {
          setCount(prevCount => prevCount + 1);
        }
      }
    
    };

    document.addEventListener('keydown', handleBackspace);
    return () => {
      document.removeEventListener('keydown', handleBackspace);
    };
  }, [backOn, effectKey]);

  useEffect(() => {
    const inputElement = document.getElementById('backSpaceSwitch');

    if (inputElement) {
      const handleBackspaceKey = (event) => {
        if (event.key === 'Backspace') {
          const condition = true; // Replace with your actual condition

          if (condition) {
            event.preventDefault();
          }
        }
      };

      const listenBackSpace = (switchState) => {
        inputElement.removeEventListener('keydown', handleBackspaceKey);

        if (switchState) {
          inputElement.addEventListener('keydown', handleBackspaceKey);
        }
      };

      listenBackSpace(backOn);
    } else {
      console.error('Input element not found');
    }
  }, [backOn,effectKey]);

  const handleSwitchClick = () => {
    setBackOn(!backOn);
    setEffectKey(prevKey => prevKey + 1);
  };

  

  const [typingTest, setTypingTest] = useState({});
  useEffect(() => {
    // Declare the async function inside useEffect
    const getTest = async () => {
      try {
        const response = await getTypingTestById(`${environment.domain}tests/typing/` + id);

        if (response.status === 200 || response.status === 201) {
          setTypingTest(response.data);
          if (response.data.type === "Hindi") {
            setInputType("kurtidev")
          }// Assuming response.data is the correct path to your data
        } else {
          console.log("Response:", response);
        }

        // Navigate on successful fetch
      } catch (error) {
        console.error('Error fetching tests:', error.message);
      }
    };

    // Call the function
    getTest();
  }, []);

  const onBackSpace = () => {
    setBackSpace(!onbackSpace)
  }


  return (
    <>
      <Navbar />



      <div className='container my-3'>
        <div className='testDetails'>
          <h4 className='text-primary'>{typingTest.test_name}</h4>
          {isStartClicked ? ('') : (<div>
            Select Time :
            <select value={selectedTime} onChange={handleTimeChange}>
              <option value={60}>1 min</option>
              <option value={120}>2 min</option>
              <option value={180}>3 min</option>
              <option value={240}>4 min</option>
              <option value={300}>5 min</option>
              <option value={600}>10 min</option>
              <option value={900}>15 min</option>
            </select>
          </div>)}

          {isStartClicked ? ('') :
            (<button onClick={handleStartClick} className='mx-2 btn btn-danger'>
              Start Typing
            </button>)}
          <div>{formatTime(timeLeft)}</div>
         
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox"  onClick={handleSwitchClick} role="switch" id="flexSwitchCheckChecked"/>
            <label class="form-check-label" for="flexSwitchCheckChecked"> <p>Backspace : <span>{count}</span></p></label>
          </div>
          <p> | &nbsp; Font Size : <span> <button onClick={increaseFontSize} className='btn btn-success'>+</button> &nbsp; <button onClick={decreaseFontSize} className='btn btn-warning' >-</button></span></p>
        </div>

        <div className='para shadow-sm' style={{ fontSize: `${fontSize}px` }}>
          <div className='typingcontent'>{typingTest.test_content} </div>
        </div>
        {isTextAreaEnabled && (
          <div  className='para2 my-3' onSubmit={handleSubmit}><textarea style={{ fontSize: `${fontSize}px !important` }}
            cols={50}
            id='backSpaceSwitch'
            placeholder=''
            className={`${inputType} `}
            rows={3}
            value={typedText}
            onChange={handleChange}
            spellCheck="false"
          /></div>
        )}
        <button onClick={handleSubmit} className='btn submitBtn btn-primary'> Submit</button>
      </div>

    </>
  );
};

export default TypingTestPage;
