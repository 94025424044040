import {React , useEffect} from 'react'
import PDFViewer from './PDFViewer'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const ShowPdf = () => {
    const {noteName} = useParams();
     
    useEffect(() => {
        const handleKeyDown = (event) => {
          event.preventDefault();
        };
    
        document.addEventListener('keydown', handleKeyDown);
    
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);
    
  return (
   <>
   <PDFViewer pdfUrl={`/pdfs/${noteName}`}/>
   </>
  )
}

export default ShowPdf