import React, { useState , useEffect} from 'react'
import './StenoResult.css'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import Navbar from '../../../components/navbar/Navbar';
import Sresult from '../../../components/result/Sresult';
const StenoResultPage = () => {
   const location = useLocation();
   const history = useHistory();
   const {data2} = location.state;
     
    const [totalIncorrectCorrectWords , setTotalIncorrectCorrectWords] = useState(0)
    const typedTextArray = data2.typedtext.split(" ");
    const originalTextArray = data2.steno_content.split(" ");


   useEffect(() => {
    // Disable back navigation
    const unblockHistoryNavigation = history.block((location, action) => {
      // If the action is POP (back navigation), prevent it
      if (action === 'POP') {
        // Show a confirmation dialog
        const confirmation = window.confirm('Are you sure you want to leave this page?');
        
        // If the user clicks OK, execute your desired logic
        if (confirmation) {
          // Your logic here
          // For example, redirect the user to another page
          window.location.href = '/dashboard';
        } else {
          // If the user clicks Cancel, prevent navigation
          return false;
        }
      }
    });

    // Clean up function to remove the event listener
    return () => {
      unblockHistoryNavigation();
    };
}, [history]);


useEffect(() => {
    const calculateCorrectWords = () =>{
        let count = 0
        for (let index = 0; index < typedTextArray.length; index++) {
            if (typedTextArray[index] !== originalTextArray[index]) {
                count++;
            }
        }
        setTotalIncorrectCorrectWords(count)
    }
    calculateCorrectWords();
},[]); 

  return (
    <>
    <Navbar/>
    <div className='my-4 row stenoHeader'>
      <p className='col-md-3'>Test Name : {data2.test_name}</p>
      <p className='col-md-3'>Duration : {data2.duration} Mins</p>
      <p className='col-md-3'>Test Speed : {data2.dictation_speed} WPM</p>
      <p className='col-md-3'>Submitted On : {data2.submitted_on}</p>
    </div>

   
    
    <div className='resultTextCompare'><Sresult userInput={data2.typedtext}  originalText={data2.steno_content} otherData={data2}  type="steno"  /></div>

    
    </>
  )
}

export default StenoResultPage