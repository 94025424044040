
  
  const getAllTests = async (url) => {
    try {
      const response = await fetch(url, {
        method: 'GET'
      });
  
      const responseData = await response.json(); // Parse JSON response
      return {status : response.status , data : responseData};
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };
  

  const getTypingTestById = async (url, id) => {
    try {
      const response = await fetch(url, {
        method: 'GET'
      });
  
      const responseData = await response.json(); // Parse JSON response
      return {status : response.status , data : responseData};
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };
  
  const getAllResults = async (url) => {
    try {
      const response = await fetch(url, {
        method: 'GET'
      });
      
      const responseData = await response.json(); 
      console.log(responseData)// Parse JSON response
      return {status : response.status , data : responseData};
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };


  const getResultById = async (url, id) => {
    try {
      const response = await fetch(url, {
        method: 'GET'
      });
      const responseData = await response.json(); 
      console.log(responseData)// Parse JSON response
      return {status : response.status , data : responseData};
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };
  

  const submitTest = async (url, data , otherData , test_id) => {
    const formdata = {
        "test_id":test_id ,
        "content": data,
        "duration":otherData.timeTaken,
        "backspace":otherData.backSpace,
        "selectedTime":otherData.selectedTime,
        "user_id":otherData.user_id
    }
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Assuming you're sending JSON data
        },
        body: JSON.stringify(formdata), // Convert data to JSON string
      });
      const responseData = await response.json(); // Parse JSON response
      return {status : response.status , data : responseData};
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };
  // Export the service functions individually
  export {getAllTests, getTypingTestById  , getAllResults,submitTest , getResultById};
  
  // Alternatively, export them all at once
  // export { registerUser, fetchUserData };
  