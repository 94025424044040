// DataService.js

// Define your service functions
const registerUser = async (url, data) => {
    const formdata = {
        "name": data.sname,
        "password": data.spassword,
        "phone": data.sphone,
        "email": data.semail
    }
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Assuming you're sending JSON data
        },
        body: JSON.stringify(formdata), // Convert data to JSON string
      });
      
      const responseData = await response.json(); // Parse JSON response
      return {status : response.status , data : responseData};
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };
  
  const loginUser = async (url, data) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Assuming you're sending JSON data
        },
        body: JSON.stringify(data), // Convert data to JSON string
      });
  
      const responseData = await response.json(); // Parse JSON response
      return {status : response.status , data : responseData};
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };
  
  const updatePassword = async (url, data) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Assuming you're sending JSON data
        },
        body: JSON.stringify(data), // Convert data to JSON string
      });
  
      const responseData = await response.json(); // Parse JSON response
      return {status : response.status , data : responseData};
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };
  
  
  
  const getUserById = async (url) => {

    try {
      const response = await fetch(url, {
        method: 'GET'
      });
  
      const responseData = await response.json(); // Parse JSON response
      return {status : response.status , data : responseData};
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };








  // Export the service functions individually
  export { registerUser, loginUser , getUserById, updatePassword };
  
  // Alternatively, export them all at once
  // export { registerUser, fetchUserData };
  