import React, { useState , useEffect } from 'react'
import Navbar from '../../components/navbar/Navbar'
import './css/Typing.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { getAllTests } from '../../services/TypingTestService';
import environment from '../../environment.json'


const TypingPage = () => {

  const history = useHistory();
  const handleStartTest = (id) => {
      history.push('/typingTest/'+id);
  }

  let [typingTests , setTypingTests] = useState([]);
  let [typingTestsRetain , setTypingTestsRetain] = useState([]);
  useEffect(() => {
    // Declare the async function inside useEffect
    const getAllTest = async () => {
      try {
        const response = await getAllTests(`${environment.domain}tests/all`);

        if (response.status === 200 || response.status === 201) {
          
          if (localStorage.getItem("package_id")==0) {
            setTypingTests(response.data.filter(obj=>obj.visibility === "Free" || obj.visibility === "free")); // Assuming response.data is the correct path to your data
          setTypingTestsRetain(response.data.filter(obj => obj.visibility=="Free" || obj.visibility === "free"))
          }else{
          setTypingTests(response.data); // Assuming response.data is the correct path to your data
          setTypingTestsRetain(response.data)       
        }
        } else {
          console.log("Response:", response);
        }

        // Navigate on successful fetch
      } catch (error) {
        console.error('Error fetching tests:', error.message);
      }
    };

    // Call the function
    getAllTest();
  }, []); 

  const filterTests = (type) => {
    typingTests = typingTestsRetain
    
    if (type !== "All") {
      const filteredTests = typingTests.filter(test => test.language === type);
    setTypingTests(filteredTests);
    }else{
      setTypingTests(typingTestsRetain)

    }
  };
  
  return (
    <>

      <Navbar />
      <div className='container'>
      <h3 className='my-3 py-3 mx-3'>Typing Tests</h3>  
        <div className='buttons d-flex py-3'>
        <button onClick={()=>filterTests("All")} className='mx-3 btn btn-outline-secondary'>All</button>
        <button onClick={()=>filterTests("Hindi")} className='mx-3 btn btn-outline-primary'>Hindi</button>
        <button onClick={()=>filterTests("English")} className='btn btn-outline-success'>English</button> 
        </div> 


        <table className="table  shadow-lg" >
          <thead className='table-info'>
            <tr >
              <th scope="col">S.No</th>
              <th scope="col">Date</th>
              <th scope="col">Test Name</th>
              <th scope="col">Test Language</th>
              <th scope="col">Total Words</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody >
           {
              typingTests && typingTests.map((item , index)=>{ return(
                <tr key={index} className='px-3'>
                <th scope="row">{index + 1}</th>
                <td>{item.date.split('T')[0]}</td>
                <td>{item.test_name}</td>
                <td>{item.language}</td>
                <td>{item.total_words} Words</td>
                <td><button onClick={()=>handleStartTest(item.typing_test_id)} className='btn btn-warning'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-keyboard" viewBox="0 0 16 16">
                  <path d="M14 5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1zM2 4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z" />
                  <path d="M13 10.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25zm0-2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25zm-5 0A.25.25 0 0 1 8.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 8 8.75zm2 0a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25zm1 2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25zm-5-2A.25.25 0 0 1 6.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 6 8.75zm-2 0A.25.25 0 0 1 4.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 4 8.75zm-2 0A.25.25 0 0 1 2.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 2 8.75zm11-2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25zm-2 0a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25zm-2 0A.25.25 0 0 1 9.25 6h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 9 6.75zm-2 0A.25.25 0 0 1 7.25 6h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 7 6.75zm-2 0A.25.25 0 0 1 5.25 6h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 5 6.75zm-3 0A.25.25 0 0 1 2.25 6h1.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-1.5A.25.25 0 0 1 2 6.75zm0 4a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25zm2 0a.25.25 0 0 1 .25-.25h5.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-5.5a.25.25 0 0 1-.25-.25z" />
                </svg>  Start</button></td>
              </tr>)
              })
           } 
          </tbody>
        </table>

      </div>
    </>
  )
}

export default TypingPage