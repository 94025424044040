import React, { useEffect, useState } from 'react'
import './Compare.css'
import Navbar from '../../components/navbar/Navbar'
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import {compareResult} from '../../services/StenoService';
import environment from '../../environment.json'
const JsDiff = require('diff');

const Comapare = () => {
  const { id } = useParams();
  const location = useLocation();
  const { steno_content } = location.state;
  let resultStr = "";
  let wrongwordcount = -1;
  let correctWordChar = 0;
  let wrongWordChar =  0;
  let correctWords = 0;
 
  // Calculate the total character excluding the spaces

  const [leaderBoard , setLeaderBoard] = useState([]);

  useEffect(() => {
    // Declare the async function inside useEffect
    const getCompareResults = async () => {
      try {
        const response = await compareResult(`${environment.domain}steno/compareResult/` + id);

        if (response.status === 200 || response.status === 201) {
          console.log(steno_content)
          // Assuming response.data is the correct path to your data
          const updatedData = response.data.map(item=>({
            ...item, 
            total_incorect_words : showResult(item.content, steno_content),
            exact_marks : ((item.total_words - showResult(item.content, steno_content))/item.total_words * 100).toFixed(2)
          }))

          const sortedData = [...updatedData].sort((a, b) => b.exact_marks - a.exact_marks);


          const rankedData = sortedData.map((item, index) => ({
            ...item,
            exact_rank: index + 1
          }));
          setLeaderBoard(rankedData);
        } else {
          console.log("Response:", response);
        }
        // Navigate on successful fetch
      } catch (error) {
        console.error('Error fetching tests:', error.message);
      }
    };
    getCompareResults();
  }, []);

  const userLogId = localStorage.getItem("user_id");


  const showResult = (userInput,originalText) => {
    let wrongwordcount = -1;
    let correctWordChar = 0;
    let wrongWordChar =  0;
    let correctWords = 0;
    const salt = "Алексей";
    userInput += " " + salt;
    let baseArr = originalText.split(" ");
    let newArr = userInput.split(" ");
    let diff = JsDiff.diffWords(baseArr.join(" "), newArr.join(" "));
    let finalResultString = [];
    try{
        for (let i = 0; i < diff.length; i++) {
            // Normal case when no addition and removal
            if ( diff[i]["added"] === undefined && diff[i]["removed"] === undefined ){

                // Code to get the no error string let and excluding the spaces count.
                let trimmedValue = diff[i]['value'].trim();
                let length = trimmedValue === '' ? 0 : trimmedValue.split(' ').length;
                correctWordChar += trimmedValue.replace(/\s+/g, '').length;
                correctWords += length;

                finalResultString.push(
                    <span style={{color: "green"}}>
                        {diff[i]["value"]}
                    </span>
                );
            }
            // when there is just removale
            else if ( diff[i]['removed'] !== undefined && diff[i]["removed"] === true && diff[i]["value"] !== "\n") {
                // Case 1: remove and next one is add.
                
                if ( i+1 < diff.length && diff[i+1]["added"] !== undefined && diff[i+1]["added"] !==  false && diff[i+1]["value"] != salt){

                    let ith_wordCharCount = diff[i]['value'].trim().replace(/\s+/g, '').length;
                    let _ith_wordCharCount = diff[i+1] == salt ? (diff[i+1]['value'].trim().replace(/\s+/g, '').length):(0);
                    
                    wrongwordcount+=1;

                    wrongWordChar += (ith_wordCharCount + _ith_wordCharCount);
                    finalResultString.push(
                        <span style={{color: "red"}}>
                           {"{"} {diff[i+1]["value"]} {"}"}
                        </span>
                    );
                    finalResultString.push(
                        <span style={{color: "blue"}}>
                           {"{"} {diff[i]["value"]} {"}"}
                        </span>
                    );
                    i++;
                }
                // Case 2: just remove
                else{
                    wrongwordcount+=(diff[i]["value"].split(" ").length - 1)
                    finalResultString.push(
                        <span style={{color: "red"}}>
                            {diff[i]["value"]} 
                        </span>
                    );
                }
            }
            // Handling the add case
            else if ( diff[i]['added'] !== undefined && diff[i]["added"] === true && diff[i]["value"] !== "\n"){
                if (diff[i]["value"].trim().toLowerCase() != salt.trim().toLowerCase()){
                    let ith_wordCharCount = diff[i]['value'].trim().replace(/\s+/g, '').length;
                    wrongWordChar += (ith_wordCharCount);
                    wrongwordcount+=1;
                    // Case 1:
                    finalResultString.push(
                        <span style={{color: "red"}}>
                            {"{"} {diff[i]["value"]} {"}"}
                        </span>
                    );
                }
                
            }else{
                continue;
            }
            
        }
    }catch(err){
        finalResultString.push(
            <span style={{color: "red"}}>
                {"Error in finding the difference!!"}
            </span>
        );
    }
    wrongwordcount++;
    resultStr = (
        <span>
            {finalResultString}
        </span>
    );
    return wrongwordcount;
}


  return (
    <>
    <Navbar />
    <div className='table2'>
    <table class="table  shadow-sm">
        <thead className='table-info'>
          <tr>
            <th scope="col">S.NO.</th>
            <th scope="col">TEST NAME</th>
            <th scope="col">ROLL NO</th>
            <th scope="col">TOTAL WORDS</th>
            <th scope="col">TOTAL TYPED</th>
            <th scope="col">INCORRECT</th>
            <th scope="col">MARK</th>
            <th scope="col">RANK</th>
          </tr>
        </thead>
        <tbody>
          {
            leaderBoard && leaderBoard.map((item, index) => {
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{item.test_name}</td>
                  <td>{item.user_id == localStorage.getItem("user_id") ? (item.roll_no + " (YOU)") : item.roll_no}</td>
                  <td>{item.total_words}</td>
                  <td>{item.content.split(" ").length}</td>
                  <td>{item.total_incorect_words}</td>
                  <td>{item.exact_marks}</td>
                  <td>{item.exact_rank}</td>
                </tr>
              )
            })

          }
        </tbody>
      </table>
    
      </div>
    </>
  )
}

export default Comapare