import {React , useState , useEffect} from 'react'
import './MainFooter.css'
import { getWhatsappNumber } from '../../../services/PackageService';
import environment from '../../../environment.json'
const MainFooter = () => {

  const [pnumber, setPNumber] = useState();
  const [alt_number, setAltNumber] = useState();
  const [address, setAddress] = useState();
  const [email, setEmail] = useState();

 
  useEffect(() => {
    // Declare the async function inside useEffect
    const getWhatsappNmber = async () => {
      try {
        const response = await getWhatsappNumber(`${environment.domain}tests/getWhatsappNumber`);

        if (response.status === 200 || response.status === 201) {
          setPNumber(response.data[0].phonenumber); // Assuming response.data is the correct path to your data
          setAltNumber(response.data[0].alt_number); // Assuming response.data is the correct path to your data
          setAddress(response.data[0].address);
          setEmail(response.data[0].email);
          
        } else {
          
        }

        // Navigate on successful fetch
      } catch (error) {
        console.error('Error fetching tests:', error.message);
      }
    };
    // Call the function
    getWhatsappNmber();
  }, []);



  return (
    <>

      <div className='mainFooter'>
        <div className='mainFooterBody'>
          <h4>Contact Us</h4>
          <p><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
          </svg> &nbsp; Address :  {address}</p>

          <p><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" class="bi bi-telephone-fill" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
          </svg> &nbsp;  Mobile: {pnumber} | {alt_number}</p>

          <p><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" class="bi bi-envelope-fill" viewBox="0 0 16 16">
            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
          </svg> &nbsp; Email :  {email}</p>
        </div>
      </div>
      <div className='mainFoot shadow-sm'>
      &#169; Copyright | Designed & Developed for Janki Steno.
      </div>

    </>
  )
}

export default MainFooter