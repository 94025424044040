import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getResultById } from '../services/TypingTestService';
import Result from '../components/result/Result';
import Navbar from '../components/navbar/Navbar'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


const Resultpage = () => {
    const location = useLocation();
    const {data} = location.state;

    console.log(data)

    return (
       
        <div>

         <Navbar />
         <h4 className='my-3 mx-3 text-primary'>{data && data.test_name}</h4> 
            {
  data && Object.keys(data).length > 0 ? (
    <Result userInput={data.result_content} originalText={data.test_content} otherData={data} type="typing" />
  ) : (
    ''
  )
}
            
        </div>
    );
}

export default Resultpage;
